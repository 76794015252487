import React, { useMemo } from 'react';
import { useBooleanState } from '@fingo/lib/hooks';
import Button from '@mui/material/Button';
import { MoneyType } from '@fingo/lib/propTypes';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import PropTypes from 'prop-types';
import { CreditLineEvaluationVariablesType } from '@fingo/lib/propTypes/CreditLineEvaluationVariablesType';
import CreditLineSuggestionInnerContent from './CreditLineSuggestionInnerContent';

const CreditLineSuggestion = ({ value, row }) => {
  const [open, toggleOpen] = useBooleanState();
  const recomendation = useMemo(() => {
    if (value === undefined) {
      return '-';
    }
    if (value.amount <= 0) return 'Rechazar';
    return value.chileFormat;
  }, [value]);
  const disabled = value === undefined;
  const color = useMemo(() => {
    if (value === undefined) return 'info';
    if (value.amount <= 0) return 'primary';
    return 'success';
  }, [value]);
  return (
    <>
      <Button
        variant="text"
        disabled={disabled}
        color={color}
        size="small"
        onClick={toggleOpen}
      >
        {recomendation}
      </Button>
      <FingoDialog
        open={open}
        handleClose={toggleOpen}
        title="Recomendación"
        maxWidth="md"
        fullWidth
      >
        <CreditLineSuggestionInnerContent
          value={value}
          row={row}
        />
      </FingoDialog>
    </>
  );
};

CreditLineSuggestion.propTypes = {
  value: MoneyType,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    creditlinesuggestionSet: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        errorLog: PropTypes.arrayOf(PropTypes.string).isRequired,
      }),
    ).isRequired,
    creditlineevaluationvariablesSet: PropTypes.arrayOf(
      CreditLineEvaluationVariablesType.isRequired,
    ),
  }).isRequired,
};
CreditLineSuggestion.defaultProps = {
  value: undefined,
};
export default CreditLineSuggestion;
